import { FormControl, TextField, Box } from "@material-ui/core";
import { FC, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useStyles } from "../../pages/Check/styles";
import { OnChangeEvent } from "types/general";

interface CheckDateSelectorProps {
  type: string;
  start_date: string | undefined;
  end_date: string | undefined;
  setRangeDates: (startDate:string, endDate:string) => void;
  removeRangeDates: () => void;
}

export const DateRangeSelector: FC<CheckDateSelectorProps> = ({
  type,
  start_date,
  end_date,
  setRangeDates,
  removeRangeDates,
}) => {
  const [startDate, setStartDate] = useState<string>(start_date || "");
  const [endDate, setEndDate] = useState<string>(end_date || "");
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(setRangeDates(startDate, endDate));
    } else {
      dispatch(removeRangeDates());
    }
  }, [startDate, endDate, dispatch]);

  return (
    <Box className={classes.selectors}>
      <FormControl fullWidth style={{ height: 50 }}>
        <TextField
          label="From:"
          variant="standard"
          name="date_one"
          type={type}
          InputLabelProps={{ shrink: true }}
          value={startDate}
          onChange={(e: OnChangeEvent) => {
            setStartDate(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth style={{ height: 50 }}>
        <TextField
          label="To:"
          variant="standard"
          name="date_two"
          type={type}
          InputLabelProps={{ shrink: true }}
          value={endDate}
          onChange={(e: OnChangeEvent) => {
            setEndDate(e.target.value);
          }}
        />
      </FormControl>
    </Box>
  );
};
