import { useEffect, useState, FC } from "react";
import { useDispatch } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Box,
  Typography,
  TablePagination,
  Tooltip,
} from "@material-ui/core";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { Colors, DISPATCHER, EMPTY } from "constants/constants";
import { DashboardRecord } from "types/DashboardRecord";
import {
  DashboardChangeRowsPerPage,
  DashboardDataListRequest,
  DashboardSendMessage,
  DashboardUpdateSettings,
} from "actions/dashboardActions";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DashboardTableRow from "components/tables/AmznScoreTables/AmznScoreTableRow";
import moment from "moment";
import { WeekSelect } from "components/elements/WeekSelect";
import { SearchInput } from "components/elements/SearchInput";
import { TotalScore, calculateTotalScore } from "components/elements/TotalScore";
import { useTableStyles } from "./styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import { ModalWindow } from "components/templateComponents/modalWindow";
import { DriverNotifyDialog } from "components/modals/modalContent/DriverNotifyDialog";
import Warning from "@material-ui/icons/WarningRounded";
import { StationSelect } from "components/elements/StationSelect";

interface AmznScoreTableProps {}

export const AmznScoreTable: FC<AmznScoreTableProps> = () => {
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const { userData } = useTypeSelectors((state) => state.auth);
  const {
    dashboardDataList,
    dashboardDataListCount,
    dashBoardDate,
    dashboardTableRowsPerPage,
    dashboardScoreSettings,
  } = useTypeSelectors((state) => state.dashboard);
  const weekToday = moment().format("W");
  const yearToday = moment().format("yyyy");
  const totalScore = calculateTotalScore(dashboardDataList);

  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState("-points_total");
  const [page, setPage] = useState(0);
  const [week, setWeek] = useState(dashBoardDate?.week_number ?? +weekToday);
  const [year, setYear] = useState(dashBoardDate?.year ?? +yearToday);

  const handleChangePagination = (event: unknown, value: number) => {
    setPage(value);
  };

  const handleReduceWeek = () => {
    setPage(0);
    if (week && year) {
      if (week === 1) {
        setWeek(52);
        setYear(year - 1);
      } else setWeek(week - 1);
    }
  };

  const handleIncreaseWeek = () => {
    setPage(0);
    if (week && year) {
      if (week === 52) {
        setWeek(1);
        setYear(year + 1);
      } else setWeek(week + 1);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(DashboardChangeRowsPerPage(+event.target.value));
    setPage(0);
  };

  const handleChangeStation = (station: string) =>
    dispatch(
      DashboardUpdateSettings({
        currentStation: station,
      }),
    );

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleSortRowsByName = (name: string) => {
    if (name !== "block_message" && name !== "text_message") {
      if (sort === name) {
        setSort(`-${name}`);
      } else {
        setSort(name);
      }
    }
  };

  useEffect(() => {
    setPage(0);
  }, [searchValue]);

  useEffect(() => {
    if (searchValue) {
      dispatch(
        DashboardDataListRequest(
          page + 1,
          dashboardTableRowsPerPage,
          sort,
          Number(dashboardScoreSettings?.currentStation),
          week,
          year,
          searchValue,
        ),
      );
    } else {
      dispatch(
        DashboardDataListRequest(
          page + 1,
          dashboardTableRowsPerPage,
          sort,
          Number(dashboardScoreSettings?.currentStation),
          week,
          year,
        ),
      );
    }
  }, [
    dashboardTableRowsPerPage,
    page,
    sort,
    searchValue,
    week,
    year,
    dispatch,
    dashboardScoreSettings?.currentStation,
  ]);

  const tableRowsDashboard = [
    { isShownBy: true, name: "№", label: "№", align: "center" },
    {
      isShownBy: true,
      name: "block_message",
      label: (
        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <NotificationsOffIcon />
        </Box>
      ),
      toolTipLabel: "Block message to driver",
      align: "center",
    },
    { isShownBy: true, name: "driver__first_name", label: "Name", align: "start" },
    {
      isShownBy: true,
      name: "driver__transporter_id",
      label: "Transporter ID",
      align: "start",
    },
    {
      isShownBy: true,
      name: "points_total",
      label: "TPC",
      toolTipLabel: "Total Points Collected",
      align: "start",
    },
    {
      isShownBy: true,
      name: "number_of_shifts",
      label: "NS",
      toolTipLabel: "Number of Shifts",
    },
    { isShownBy: true, name: "quadrant", label: "Quadrant" },
    {
      isShownBy: true,
      name: "current week tier",
      sortingName: "record_data__cwt",
      label: "CWT",
      toolTipLabel: "Current Week Tier",
    },
    {
      isShownBy: true,
      name: "6 week average tier",
      label: "6W AT",
      toolTipLabel: "6 Week Average Tier",
    },
    {
      isShownBy: true,
      name: "tenured",
      sortingName: "record_data__tenured",
      label: "Tenured",
    },
    {
      isShownBy: true,
      name: "dcr",
      sortingName: "record_data__dcr",
      label: "DCR",
      toolTipLabel: "Delivery Completion Rate",
    },
    {
      isShownBy: true,
      name: Boolean(+year > 2023 || (+week > 34 && +year <= 2023)) ? "dsb" : "dar",
      label: Boolean(+year > 2023 || (+week > 34 && +year <= 2023)) ? "DSB" : "DAR",
      toolTipLabel: Boolean(+year > 2023 || (+week > 34 && +year <= 2023))
        ? "Delivery Success Behaviors"
        : "Delivered And Received",
    },
    {
      isShownBy: true,
      name: "pod",
      sortingName: "record_data__swc_pod",
      label: "POD",
      toolTipLabel: "Photo On Delivery",
    },
    {
      isShownBy: true,
      name: "cc",
      sortingName: "record_data__swc_cc",
      label: "CC",
      toolTipLabel: "Contact Compliance",
    },
    {
      isShownBy: true,
      name: "sc",
      label: "SC",
      toolTipLabel: "Scan Compliance",
    },
    {
      isShownBy: true,
      name: "ced",
      sortingName: "record_data__ced",
      label: "CED",
      toolTipLabel: "Customer Escalation Defect",
    },
    {
      isShownBy: true,
      name: "High Performer in Weeks",
      sortingName: "record_data__high_performer_in_weeks",
      label: "HP 6W",
      toolTipLabel: "High Performer in Weeks",
    },

    { isShownBy: true, name: "rescue", label: "Rescues" },
    {
      isShownBy: true,
      name: "morning_checklist",
      label: "MC",
      toolTipLabel: "Morning Checklist",
    },
    {
      isShownBy: true,
      name: "ontime_attendance",
      label: "OA",
      toolTipLabel: "Ontime Attendance",
    },
    { isShownBy: true, name: "text_message", label: "Message" },
  ];

  const onCreateReport = () => {
    dispatch(DashboardSendMessage());
  };

  return (
    <Box className={classes.root}>
      <Paper className={classes.headBox}>
        <Box className={classes.filters}>
          {userData.role === DISPATCHER ? null : (
            <ModalWindow
              modal_name={"driver-notofication-modal"}
              buttonName={"DA Notify"}
              modalTitle={"Driver Notifications"}
              modalText={"Are you sure you want to notify all drivers from the last created list about their rating. They will receive SMS."}
              hasIcon
              icon={<Warning />}
              theme={"yellow"}
            >
              <DriverNotifyDialog
                onSubmit={onCreateReport}
              />
            </ModalWindow>
          )}
          <SearchInput value={searchValue} onChange={handleSearch} />
          <StationSelect
            value={Number(dashboardScoreSettings?.currentStation)}
            onChange={handleChangeStation}
            stations={userData?.station_spots.filter((item)=> ["DON9", "HYZ1"].includes(item.station.tag))}
          />
          <WeekSelect
            week={week}
            year={year}
            onNext={handleIncreaseWeek}
            onPrev={handleReduceWeek}
          />
          <TotalScore score={totalScore} />
        </Box>
        <Tooltip
          title={
            <Box>
              <Box style={{ background: Colors.PASTEL_BLUE, padding: "5px" }}>
                <Typography>Fantastic</Typography>
              </Box>
              <Box style={{ background: Colors.PASTEL_GREEN, padding: "5px" }}>
                <Typography>Great</Typography>
              </Box>
              <Box style={{ background: Colors.PASTEL_YELLOW, padding: "5px" }}>
                <Typography>Fair</Typography>
              </Box>
              <Box style={{ background: Colors.PASTEL_RED, padding: "5px" }}>
                <Typography>Poor</Typography>
              </Box>
            </Box>
          }
        >
          <InfoOutlinedIcon />
        </Tooltip>

        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 50, 100]}
          count={dashboardDataListCount}
          rowsPerPage={dashboardTableRowsPerPage}
          page={page}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePagination}
        />
      </Paper>
      <Box>
        <TableContainer component={Paper} className={classes.body}>
          <Table stickyHeader>
            <TableHead>
              {tableRowsDashboard.map((TableRows) => {
                if (!TableRows?.isShownBy) return null;
                return (
                  <Tooltip
                    title={
                      TableRows.toolTipLabel ? (
                        <Typography variant="body2">{TableRows.toolTipLabel}</Typography>
                      ) : (
                        ""
                      )
                    }
                  >
                    <TableCell
                      className={classes.tableWhiteCell}
                      key={TableRows.name}
                      onClick={() => handleSortRowsByName(TableRows?.sortingName || TableRows.name)}
                      style={
                        TableRows.name === "dcr" || TableRows.name === "rescue"
                          ? { borderLeft: `1px solid ${Colors.GRAY}` }
                          : {}
                      }
                    >
                      <Box
                        className={classes.BoxIcon}
                        style={{
                          ...(TableRows?.align ? { justifyContent: TableRows?.align } : {}),
                        }}
                      >
                        <Typography variant="h6" style={{ cursor: "pointer" }}>
                          {TableRows.label}
                        </Typography>
                        {sort === `-${TableRows?.sortingName || TableRows.name}` && (
                          <ArrowUpward style={{ fontSize: 18 }} />
                        )}
                        {sort === `${TableRows?.sortingName || TableRows.name}` && (
                          <ArrowDownward style={{ fontSize: 18 }} />
                        )}
                      </Box>
                    </TableCell>
                  </Tooltip>
                );
              })}
            </TableHead>

            <TableBody style={{ maxHeight: "calc(100vh - 222px)" }}>
              {dashboardDataList.length
                ? dashboardDataList.map((row: DashboardRecord, index: number) => (
                    <DashboardTableRow row={row} index={index} week={week} year={year} />
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        {!dashboardDataList.length && (
          <Typography variant="h1" className={classes.emptyTable}>
            {EMPTY}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
