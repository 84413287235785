import { makeStyles } from "@material-ui/core/styles";
import { FC, useEffect } from "react";
import { Box, Button, FormGroup } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useNav } from "hooks/useNav";
import { useDateWeekSelector } from "hooks/useDateWeekSelector";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { DashboardUpdateDateSettings, DashboardUpdateSettings, resetTrendsRangeDates, updateTrendsRangeDates } from "actions/dashboardActions";
import { YearSelect } from "components/elements/YearSelect";
import { DashboardDrawerButton } from "components/buttons/DashboardDrawerButton";
import { DashboardTrendsTemlate } from "./DashboardTrendsTemlate";
import { RoutesTrends } from "./DashboardCharts/RoutesTrends";
import { DeliveryTrends } from "./DashboardCharts/DeliveryTrends";
import { ReturnsTrends } from "./DashboardCharts/ReturnsTrends";
import { OtherReturnsTrends } from "./DashboardCharts/OtherReturnsTrends";
import { DateRangeSelector } from "components/elements/DateRangeSelector";
import { trendsRangeFilters } from "constants/constants";

interface PropsType {}

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  topSection: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  topSctionBox: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    flexWrap: "wrap",
  },
  leftButton: {
    boxSizing: "border-box",
    borderRadius: "25px 0 0 25px",
  },
  midButton: {
    boxSizing: "border-box",
    borderRadius: "0",
  },
  rightButton: {
    boxSizing: "border-box",
    borderRadius: "0 25px 25px 0",
  },
  "@media (max-width: 700px)": {
    topSection: {
      flexDirection: "column",
      gap: 10,
      paddingBottom: theme.spacing(1),
    },
    topSctionBox: {
      justifyContent: "center",
    },
  },
}));

const DashboardTrendsPage: FC<PropsType> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { slug } = useNav().pageParams;
  const { userData } = useTypeSelectors((state) => state.auth);
  const { dashboardScoreSettings } = useTypeSelectors((state) => state.dashboard);

  const { viewDashboard } = useNav();

  const handleChangeYear = (year: string) => dispatch(DashboardUpdateDateSettings({ year }));

  const handleChangeExternalSelectType = (select: string) => {
    dispatch(
      DashboardUpdateSettings({
        currentDateTypeLabelDateType: select,
      }),
    );
  };

  const {
    year,
    selectType,
    onTypeExternalChange,
    dateConditionYear,
    onYearIncrease,
    onYearReduce,
  } = useDateWeekSelector({
    defaultYear: dashboardScoreSettings?.labelDate?.year,
    defaultSelectType: dashboardScoreSettings?.currentDateTypeLabelDateType,
    onSelectTypeSet: handleChangeExternalSelectType,
    onYearSet: handleChangeYear,
  });

  useEffect(() => {
    if (userData.id) {
      dispatch(
        DashboardUpdateSettings({
          currentStations: userData?.station_spots.map(
            (el: { station: { title: string; id: number } }) => el.station.id,
          ),
        }),
      );
    }
  }, [userData, dispatch]);

  return (
    <Box data-testid="testDashboard" className={classes.root}>
      <Box className={classes.topSection}>
        <Button
          variant="contained"
          color="inherit"
          onClick={viewDashboard}
        >
          Back to Dashboard
        </Button>
        <Box className={classes.topSctionBox}>
          {selectType === "annual" && (
            <YearSelect
              year={year}
              onNext={onYearIncrease}
              onPrev={onYearReduce}
              disabled={dateConditionYear()}
            />
          )}
          {
            trendsRangeFilters.map(({conditionType, type})=>{
              return selectType === conditionType && (
                <DateRangeSelector
                  key={type}
                  type={type}
                  start_date={dashboardScoreSettings?.labelDate?.startDate}
                  end_date={dashboardScoreSettings?.labelDate?.endDate}
                  setRangeDates={updateTrendsRangeDates}
                  removeRangeDates={resetTrendsRangeDates}
                />
              )
            })
          }
          <FormGroup>
            <Box>
              <Button
                variant={selectType === "annual" ? "contained" : "outlined"}
                name="annual"
                className={classes.leftButton}
                onClick={() => {
                  onTypeExternalChange("annual");
                }}
              >
                Annual
              </Button>
              <Button
                variant={selectType === "month" ? "contained" : "outlined"}
                name="month"
                className={classes.midButton}
                onClick={() => {
                  onTypeExternalChange("month");
                }}
              >
                Monthly
              </Button>
              <Button
                variant={selectType === "week" ? "contained" : "outlined"}
                name="week"
                className={classes.midButton}
                onClick={() => {
                  onTypeExternalChange("week");
                }}
              >
                Weekly
              </Button>
              <Button
                variant={selectType === "day" ? "contained" : "outlined"}
                name="day"
                className={classes.rightButton}
                onClick={() => {
                  onTypeExternalChange("day");
                }}
              >
                Daily
              </Button>
            </Box>
          </FormGroup>
          <DashboardDrawerButton />
        </Box>
      </Box>
      <DashboardTrendsTemlate GraphConponent={getTrendsPage(slug)} />
    </Box>
  );
};

const getTrendsPage = (slug: string | null) => {
  switch (slug) {
    case "routes":
      return RoutesTrends;
    case "deliveries":
      return DeliveryTrends;
    case "returns":
      return ReturnsTrends;
    case "missing":
      return OtherReturnsTrends;
  }
};

export default DashboardTrendsPage;
