import { allMonthsShort } from "constants/constants";
import { DeliveriesTrendsData, RoutesTrendsData } from "types/DashboardRecord";

export const getTrendsLabel = (
  elementLabel: DeliveriesTrendsData | RoutesTrendsData,
  selectType: string | undefined,
) => {
  switch (selectType) {
    case "day":
      return elementLabel?.date;
    case "week":
      return `Week ${Number(elementLabel?.week) + 1}, ${elementLabel?.year}`;
    case "annual":
    case "month":
      return `${allMonthsShort[Number(elementLabel?.month) - 1]}, ${elementLabel?.year}`;
  }
};
