import { FC } from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { InfoBox } from "components/elements/InfoBox";

interface ReportPlanHeadProps {}

export const ReportPlanHead: FC<ReportPlanHeadProps> = () => {
  const classes = useStyles();
  const { reportPlans } = useTypeSelectors(
    (state) => state.dasBoard,
  );

  return (
    <Paper className={classes.paper}>
      <Typography variant="h4">Plans:</Typography>
        <Box className={classes.planSection}>
          <InfoBox label={"No of Routes"} value={Number(reportPlans?.plan_no_of_routes)} />
          <InfoBox label={"No of Deliveries"} value={Number(reportPlans?.plan_no_of_deliveries)} />
          <InfoBox label={"No of DA"} value={Number(reportPlans?.plan_no_of_da)} />
          <InfoBox label={"No of Fleet"} value={Number(reportPlans?.plan_no_of_fleet)} />
        </Box>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(5),
    alignItems: "center",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    flexWrap: "wrap",
    borderRadius: 15,
    boxShadow: "none",
    borderBottom: "#e8e9ee 2px solid",
  },
  planSection: {
    display: "flex",
    gap: theme.spacing(3),
    alignItems: "center",
  },
}));
