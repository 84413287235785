import { UploadFileDialog } from "components/modals/modalContent/UploadFileDialog";
import { UploadCsvFileDialog } from "components/modals/modalContent/UploadCsvFileDialog";
import { UploadReliabilityFileDialog } from "components/modals/modalContent/UploadReliabilityFileDialog";
import { UploadExelWhcDialog } from "components/modals/modalContent/UploadExelWhcDialog";

export const fileUploadButtons = [
  {
    modalName: "upload-amazon-modal",
    modalTitle: "Upload Weekly Amazon Report (.pdf)",
    modalDescription: "Attention! Fill in the number of the page where “DA Current Week Performance” table starts. After uploading files, please waiting to processing data. It should be around 10 min.",
    component: <UploadFileDialog />,
  },
  {
    modalName: "upload-new-csv-modal",
    modalTitle: "Upload Weekly Amazon Report (.csv)",
    modalDescription: "Attention! After uploading files, please waiting to processing data. It should be around 10 min.",
    component: <UploadCsvFileDialog />,
  },
  {
    modalName: "upload-reliability-data-modal",
    modalTitle: "Upload Reliability Scores (.pdf, .xlsx)",
    modalDescription: "Attention! After uploading files, please waiting to processing data. It should be around 15 sec.",
    component: <UploadReliabilityFileDialog />,
  },
  {
    modalName: "upload-xlsx-whc-model",
    modalTitle: "Upload Check WHC Value (.xlsx)",
    modalDescription: "Attention! After uploading files, please waiting to processing data. It should be around 15 sec.",
    component: <UploadExelWhcDialog />,
  },
];
