import api, { apiErrorHandler } from "api";
import { BudgetConstants } from "constants/constants";
import { Dispatch } from "redux";
import { RootState } from "reducers/rootReducer";
import { AddBudgetData, BudgetRow } from "types/Budgets";

export const getAllBudgets =
  (data: { pageNumber: number; rowsPerPage: number }) => async (dispatch: Dispatch) => {
    const onError = () => dispatch({ type: BudgetConstants.BUDGETS_ERROR });
    dispatch({ type: BudgetConstants.BUDGETS_LOADING });
    api
      .getAllBudgets({
        page: data.pageNumber,
        page_size: data.rowsPerPage,
      })
      .then((res) => {
        if (res?.data) {
          dispatch({
            type: BudgetConstants.BUDGETS_GET_ALL_SUCSESS,
            payload: {
              dataList: res?.data?.results,
              count: res?.data?.count,
            },
          });
        }
      })
      .catch(apiErrorHandler(onError));
  };

export const setBudgetPageFilters =
  (data: { rowsPerPage?: number; pageNumber?: number; sort?: string }) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: BudgetConstants.BUDGETS_TABLE_ROWS_PER_PAGE,
      payload: data,
    });
  };

export const createBudgetAction =
  (data: AddBudgetData) => async (dispatch: Dispatch, getStore: () => RootState) => {
    const { budgetList } = getStore()?.budgetBoard;
    const onError = () => dispatch({ type: BudgetConstants.BUDGETS_ERROR });
    dispatch({ type: BudgetConstants.BUDGETS_LOADING });
    api
      .createBudget({
        station: data.station,
        month: data.inputMonth,
        year: data.inputYear,
        budget_no_of_routes: data.planRoutes,
        budget_no_of_deliveries: data.planDeliveries,
        budget_no_of_da: data.planDA,
        budget_no_of_fleet: data.planFleet,
      })
      .then((res) => {
        if (res?.data) {
          dispatch({
            type: BudgetConstants.BUDGET_CREATE_ACTION_SUCCESS,
            payload: [res?.data, ...budgetList],
          });
        }
      })
      .catch(apiErrorHandler(onError));
  };

export const handleUpdateBudgetRow =
  (id: number, data: {[key: string]: number}) => async (dispatch: Dispatch, getStore: () => RootState) => {
    const { budgetList } = getStore()?.budgetBoard;
    const onError = () => dispatch({ type: BudgetConstants.BUDGETS_ERROR });
    dispatch({ type: BudgetConstants.BUDGETS_LOADING });
    api
      .updateBudget(id, data)
      .then((res) => {
        if (res?.data) {
          const newBudgetList =
            budgetList?.map((el: BudgetRow) => (el.id === res?.data.id ? res?.data : el)) || [];
          dispatch({
            type: BudgetConstants.BUDGETS_UPDATE_BUDGET_SUCSESS,
            payload: newBudgetList,
          });
        }
      })
      .catch(apiErrorHandler(onError));
  };

export const deleteBudgetAction =
  (id: number) => async (dispatch: Dispatch, getStore: () => RootState) => {
    const { budgetList } = getStore()?.budgetBoard;
    const onError = () => dispatch({ type: BudgetConstants.BUDGETS_ERROR });
    dispatch({ type: BudgetConstants.BUDGETS_LOADING });
    const newBudgetList = budgetList?.filter((el: BudgetRow) => el.id !== id);
    api
      .deleteBudget(id)
      .then((res) => {
        dispatch({
          type: BudgetConstants.BUDGETS_DELETE_BUDGET_SUCSESS,
          payload: { dataList: newBudgetList, count: newBudgetList.length },
        });
      })
      .catch(apiErrorHandler(onError));
  };
