import { AxiosError } from "axios";
import apiClient from "axios/axios";
import {
  ID,
  DriversParams,
  LoginParams,
  NotifyParams,
  PaginationParams,
  StatsParams,
  UsersDAParams,
  DAPaginationParams,
  WeightParams,
  GlobalViewParams,
} from "./types";
import { FleetSettingsProp } from "types/FleetSettings";
import { UpdateCustomGraphPageData, UpdateGraphItemData } from "types/CustomGraphs";
import { GetIkeaAllActs, UpdateIkeaActTableTypes } from "types/IkeaAct";
import { GetAllActs, UpdateActTableTypes } from "types/Act";

export const AUTH = {
  user: () => `api/current-user/`,
  token: () => `api/auth/token/`,
  refresh: () => `api/auth/token/refresh/`,
  password_reset: () => `api/auth/password_reset/`,
  user_update: (id: ID) => `api/users/${id}/`,
};

export const AMAZON = {
  upload: () => `api/upload-amazon-pdf/`,
  uploadReliability: () => `api/upload-reliability-pdf/`,
  uploadReliabilityXlsx: () => `api/upload-reliability-xlsx/`,
  uploadCsv: () => `api/upload-csv/`,
  uploadXlsxWhc: () => "api/upload-xlsx-whc/",
  uploadXlsxShiftData: () => "api/upload-xlsx-shift-data/"
};

export const DASHBOARD = {
  root: () => `api/dashboard/`,
  get_week: () => `api/dashboard/get_week_number/`,
  send_notify: () => `api/send-notification-to-drivers/`,
  weights: () => `api/weights/`,
  statistic_delivered_returned: () => `api/statistic/delivered_returned/`,
  csv_statistic_delivered_returned: () => `api/statistic/csv_delivered_returned/`,
  statistic_routes: () => `api/statistic/number_of_routes/`,
  statistic_active_days: () => `api/statistic/active_days/`,
  statistic_average_statuses: () => `api/statistic/average_and_statuses/`,
  block_notify: (id: number) => `api/block-notification-to-drivers/${id}/`,
};

export const STATS = {
  stations: "api/stations/",
  countries: "api/countries/",
  drivers: "api/statistic/drivers_data/",
  packages: "api/statistic/packages_data/",
  status: "api/statistic/status_scorecard/",
  performers: "api/statistic/performers_data/",
  returned: "api/statistic/returned_data/",
  roles: "api/statistic/roles_data/",
  vans: "api/statistic/vans_data/",
  missing: "api/statistic/missing_data/",
  cash: "api/statistic/cash_on_delivery/",
  dar: "api/statistic/worst_dar/",
  fleet_table_week: "api/statistic/vans_table_week/",
  fleet_table_month: "api/statistic/vans_table_month/",
  fleet_table_year: "api/statistic/vans_table_year/",
  late_attendance: "api/statistic/late_attendance/",
  launchpad_support: "api/statistic/launchpad_support/",
  morning_checklist: "api/statistic/morning_checklist/",
  rescue: "api/statistic/rescue/",
  returnedDataByDriver: "api/statistic/returned_data_by_driver/",
  reliabilityScore: "api/statistic/reliability_score/",
  routesStats: "api/statistic/routes_stats/",
  deliveryStats: "api/statistic/deliveries_stats/",
  routesTrends: "api/statistic/routes_trends/",
  deliveryTrends: "api/statistic/deliveries_trends/",
  daStatuses: "api/statistic/da_statuses/",
  fleetStatuses: "api/statistic/fleet_statuses/",
};

export const DAILY = {
  root: () => `api/da-daily-report/`,
  current: (id: ID) => `api/da-daily-report/${id}/`,
  current_record: (id?: ID) => `api/da-daily-report-record/${id ? id + "/" : ""}`,
  assignment: (id?: ID) => `api/driver-van-assignment/${id ? id + "/" : ""}`,
  assignment_size: (id: ID) => `api/driver-van-assignment/${id}/van_assignment/`,
  send_message: () => `api/send-message-for-drivers/`,
  get_drivers: () => `api/driver-model/`,
  users: () => `api/users/`,
  getSizes: () => `api/fleet-size/`,
  uploadTotalDeliveriesFile: () => `api/upload-deliveries-csv/`
};

export const VAN = {
  van: (id?: ID) => `api/van/${id ? id + "/" : ""}`,
  vanGroup: () => "api/van-group/",
  van_blocking: (id?: ID) => `api/van-blocking/${id ? id + "/" : ""}`,
};

export const USERS = {
  users_list: () => "/api/users/",
  give_access: (id: ID) => `/api/users/${id}/give_access_to_create_old_shifts/`,
};

export const GLOBAL_VIEW = {
  globalView: () => "api/statistic/global_view/",
  globalView2: () => "api/statistic/global_view_2/",
};

export const CHECK_TABLE = {
  getWeeklyCheck: () => "/api/weekly-check/",
  getWeeklyCheckByDays: (id: number) => `/api/weekly-check/${id}/`,
  createWeeklyCheckByUpload: () => "api/upload-quality/",
  updateDataByDay: (id: number) => `/api/daily-check/${id}/`,
};

export const ACT_TABLE = {
  getActList: () => "/api/act/",
  updateActItemById: (id: number) => `/api/act/${id}/`,
};

export const IKEA_CHECK_TABLE = {
  getWeeklyIkeaCheck: () => "/api/weekly-ikea-check/",
  getWeeklyIkeaCheckByDays: (id: number) => `/api/weekly-ikea-check/${id}/`,
  updateDataByDay: (id: number) => `/api/daily-ikea-check/${id}/`,
};

export const IKEA_ACT_TABLE = {
  getIkeaActList: () => "/api/act-ikea/",
  updateIkeaActItemById: (id: number) => `/api/act-ikea/${id}/`,
};

export const CUSTOM_GRAPHS = {
  getGraphBoards: () => "/api/graph-board/",
  getGraphBoard: (id: number) => `/api/graph-board/${id}/`,
  createGraphItem: () => "/api/graph-item/",
  updateGraphItem: (id: number) => `/api/graph-item/${id}/`,
  getEndpointList: () => `/api/graph-endpoint-list/`,
  getCustomData: (endpoint: string) => `/api${endpoint}/`
}

export const BUDGET = {
  getBudgets: () => `/api/budget/`,
  getBudget: (id: number) => `/api/budget/${id}/`,
}

const multipart = { "Content-Type": "multipart/form-data" };

const api = {
  // AUTH
  getCurrentUser: () => apiClient.get(AUTH.user()),
  updateCurrentUser: (id: ID, data: any) => apiClient.put(AUTH.user_update(id), data),
  passwordReset: (data: { email: string }) => apiClient.post(AUTH.password_reset(), data),
  login: (data: LoginParams) => apiClient.post(AUTH.token(), data),

  // AMAZON
  getAmazonHistory: (params: PaginationParams) => apiClient.get(AMAZON.upload(), { params }),
  getCSVHistory: (params: PaginationParams) => apiClient.get(AMAZON.uploadCsv(), { params }),
  uploadPdf: (request: any) => apiClient.post(AMAZON.upload(), request, { headers: multipart }),
  uploadCsv: (request: any) => apiClient.post(AMAZON.uploadCsv(), request, { headers: multipart }),
  uploadXlsxWhc: (request: any) => apiClient.post(AMAZON.uploadXlsxWhc(), request, { headers: multipart }),
  uploadXlsxShiftData: (request: any) => apiClient.post(AMAZON.uploadXlsxShiftData(), request, { headers: multipart }),
  uploadReliabilityPdf: (request: any) => apiClient.post(AMAZON.uploadReliability(), request, { headers: multipart }),
  uploadReliabilityXlsx: (request: any) => apiClient.post(AMAZON.uploadReliabilityXlsx(), request, { headers: multipart }),

  // DASHBOARD
  getDashboardWeek: () => apiClient.get(DASHBOARD.get_week()),
  getDashboardInfo: (params: PaginationParams) => apiClient.get(DASHBOARD.root(), { params }),
  sendNotificationDrivers: () => apiClient.get(DASHBOARD.send_notify()),
  getWeights: () => apiClient.get(DASHBOARD.weights()),
  updateWeights: (data: WeightParams) => apiClient.patch(DASHBOARD.weights(), data),
  getStatisticDeliveredReturned: (params: PaginationParams) =>
    apiClient.get(DASHBOARD.statistic_delivered_returned(), { params }),
  getCsvStatisticDeliveredReturned: (params: PaginationParams) =>
    apiClient.get(DASHBOARD.csv_statistic_delivered_returned(), { params }),
  getStatisticRoutes: (params: PaginationParams) =>
    apiClient.get(DASHBOARD.statistic_routes(), { params }),
  getStatisticActiveDays: (params: PaginationParams) =>
    apiClient.get(DASHBOARD.statistic_active_days(), { params }),
  getStatisticAverageStatuses: (params: PaginationParams) =>
    apiClient.get(DASHBOARD.statistic_average_statuses(), { params }),
  blockNotificationDrivers: (id: number, ignore_sms: boolean) =>
    apiClient.patch(DASHBOARD.block_notify(id), { ignore_sms }),

  // DA REPORTS
  getReports: (params: DAPaginationParams) => apiClient.get(DAILY.root(), { params }),
  getReport: (id: ID) => apiClient.get(DAILY.current(id)),
  createReport: (data: any) => apiClient.post(DAILY.root(), data),
  deleteReport: (id: ID) => apiClient.delete(DAILY.current(id)),
  sendMessageDrivers: (params: NotifyParams) => apiClient.get(DAILY.send_message(), { params }),
  getUsers: (params: UsersDAParams) => apiClient.get(DAILY.users(), { params }),
  getFleetSizes: () => apiClient.get(DAILY.getSizes()),
  uploadTotalDeliveriesFile: (request: any) =>
    apiClient.post(DAILY.uploadTotalDeliveriesFile(), request, { headers: multipart }),

  // REPORT RECORD
  createReportRecord: (id: ID) => apiClient.post(DAILY.current_record(), { report: id }),
  updateReportRecord: (id: ID, data: any) => apiClient.patch(DAILY.current_record(id), data),
  deleteReportRecord: (id: ID) => apiClient.delete(DAILY.current_record(id)),

  // BUDGET
  getAllBudgets: (params: PaginationParams) => apiClient.get(BUDGET.getBudgets(), { params }),
  createBudget: (data: any) => apiClient.post(BUDGET.getBudgets(), data),
  updateBudget: (id: ID, data: any) => apiClient.patch(BUDGET.getBudget(id), data),
  deleteBudget: (id: ID) => apiClient.delete(BUDGET.getBudget(id)),

  // ASSIGNMENTS
  getAvailableDrivers: (params: DriversParams) => apiClient.get(DAILY.get_drivers(), { params }),
  setDriverToAssignment: (data: any) => apiClient.post(DAILY.assignment(), data),
  setSizeToAssignment: (id: ID, data: any) => apiClient.put(DAILY.assignment_size(id), data),
  deleteAssignment: (id: ID) => apiClient.delete(DAILY.assignment(id)),
  getDriverList: () => apiClient.get(DAILY.get_drivers(), { params: { page_size: 1000 } }),

  // STATS
  getStations: () => apiClient.get(STATS.stations),
  getCountries: () => apiClient.get(STATS.countries),
  getReliabilityScores: (params: StatsParams) => apiClient.get(STATS.reliabilityScore, { params }),
  getRoutesStats: (params: StatsParams) => apiClient.get(STATS.routesStats, { params }),
  getDeliveryStats: (params: StatsParams) => apiClient.get(STATS.deliveryStats, { params }),
  getRoutesTrends: (params: StatsParams) => apiClient.get(STATS.routesTrends, { params }),
  getDeliveryTrends: (params: StatsParams) => apiClient.get(STATS.deliveryTrends, { params }),
  getStatsReturned: (params: StatsParams) => apiClient.get(STATS.returned, { params }),
  getStatsPackages: (params: StatsParams) => apiClient.get(STATS.packages, { params }),
  getStatsPerformers: (params: StatsParams) => apiClient.get(STATS.performers, { params }),
  getStatsRoles: (params: StatsParams) => apiClient.get(STATS.roles, { params }),
  getStatsDrivers: (params: StatsParams) => apiClient.get(STATS.drivers, { params }),
  getStatsStatuses: (params: StatsParams) => apiClient.get(STATS.status, { params }),
  getStatsVans: (params: StatsParams) => apiClient.get(STATS.vans, { params }),
  getStatsMissing: (params: StatsParams) => apiClient.get(STATS.missing, { params }),
  getStatsCash: (params: StatsParams) => apiClient.get(STATS.cash, { params }),
  getStatsDar: (params: StatsParams) => apiClient.get(STATS.dar, { params }),
  getDAStatuses: (params: StatsParams) => apiClient.get(STATS.daStatuses, { params }),
  getFleetStatuses: (params: StatsParams) => apiClient.get(STATS.fleetStatuses, { params }),

  getStatsLateAttendance: (params: StatsParams) => apiClient.get(STATS.late_attendance, { params }),
  getStatsLaunchpadSupport: (params: StatsParams) =>
    apiClient.get(STATS.launchpad_support, { params }),
  getStatsMorningChecklist: (params: StatsParams) =>
    apiClient.get(STATS.morning_checklist, { params }),
  getStatsRescue: (params: StatsParams) => apiClient.get(STATS.rescue, { params }),
  getStatsReturnedDataByDriver: (params: StatsParams) =>
    apiClient.get(STATS.returnedDataByDriver, { params }),

  getFleetWeekTable: (params: StatsParams) => apiClient.get(STATS.fleet_table_week, { params }),
  getFleetMonthTable: (params: StatsParams) => apiClient.get(STATS.fleet_table_month, { params }),
  getFleetYearTable: (params: StatsParams) => apiClient.get(STATS.fleet_table_year, { params }),

  //VAN
  getDeactivatedVans: (params: DAPaginationParams) => apiClient.get(VAN.van_blocking(), { params }),
  postDeactivateVan: (data: FleetSettingsProp) => apiClient.post(VAN.van_blocking(), data),
  patchActivateVan: (id: number, data: FleetSettingsProp) =>
    apiClient.patch(VAN.van_blocking(id), data),
  getVans: (params?: DAPaginationParams) => apiClient.get(VAN.van(), { params }),
  patchVan: (id: ID, data: any) => apiClient.patch(VAN.van(id), data),
  getVanGroups: (params?: DAPaginationParams) => apiClient.get(VAN.vanGroup(), { params }),

  //USERS
  getSuperUsersList: () => apiClient.get(USERS.users_list(), { params: { is_superusers: true } }),
  getUsersList: () => apiClient.get(USERS.users_list(), { params: { is_managers: true } }),
  setUserAccess: (id: ID) => apiClient.post(USERS.give_access(id)),

  //GLOBAL VIEW
  getGlobalView: (params: GlobalViewParams) => apiClient.get(GLOBAL_VIEW.globalView(), { params }),
  getGlobalView2: (params: GlobalViewParams) => apiClient.get(GLOBAL_VIEW.globalView2(), { params }),

  //CHECK TABLE
  getCheckTableWeeklyData: (params?: PaginationParams) =>
    apiClient.get(CHECK_TABLE.getWeeklyCheck(), { params }),
  getCheckTableWeeklyDataByDays: (id: ID) => apiClient.get(CHECK_TABLE.getWeeklyCheckByDays(id)),
  updateCheckTableWeeklyData: (id: ID, data: {[key: string]: string | number}) =>
    apiClient.patch(CHECK_TABLE.getWeeklyCheckByDays(id), data),
  createCheckTableByUploadFile: (request: FormData) =>
    apiClient.post(CHECK_TABLE.createWeeklyCheckByUpload(), request, { headers: multipart }),
  updateCheckTableDayData: (id: ID, data: {[key: string]: string | number}) =>
    apiClient.patch(CHECK_TABLE.updateDataByDay(id), data),

  //ACT_TABLE
  getActTableData: (params?: GetAllActs) => apiClient.get(ACT_TABLE.getActList(), { params }),
  updateActTableData: (id: ID, data: UpdateActTableTypes) =>
    apiClient.patch(ACT_TABLE.updateActItemById(id), data),
  
  //IKEA CHECK TABLE
  getIkeaCheckTableWeeklyData: (params?: PaginationParams) =>
    apiClient.get(IKEA_CHECK_TABLE.getWeeklyIkeaCheck(), { params }),
  getIkeaCheckTableWeeklyDataByDays: (id: ID) => apiClient.get(IKEA_CHECK_TABLE.getWeeklyIkeaCheckByDays(id)),
  updateIkeaCheckTableWeeklyData: (id: ID, data: {[key: string]: string | number}) =>
    apiClient.patch(IKEA_CHECK_TABLE.getWeeklyIkeaCheckByDays(id), data),
  updateIkeaCheckTableDayData: (id: ID, data: {[key: string]: string | number}) =>
    apiClient.patch(IKEA_CHECK_TABLE.updateDataByDay(id), data),

  //ACT_IKEA_TABLE
  getIkeaActTableData: (params?: GetIkeaAllActs) => apiClient.get(IKEA_ACT_TABLE.getIkeaActList(), { params }),
  updateIkeaActTableData: (id: ID, data: UpdateIkeaActTableTypes) =>
    apiClient.patch(IKEA_ACT_TABLE.updateIkeaActItemById(id), data),

  //CUSTOM_GRAPH
  getGraphBoards: () => apiClient.get(CUSTOM_GRAPHS.getGraphBoards()),
  getGraphBoard: (id: ID) => apiClient.get(CUSTOM_GRAPHS.getGraphBoard(id)),

  createGrahPage: (data?: { custom_graphs: []; }) => apiClient.post(CUSTOM_GRAPHS.getGraphBoards(), data),
  updateCustomGraphBoardData: (id: ID, data: UpdateCustomGraphPageData) => apiClient.patch(CUSTOM_GRAPHS.getGraphBoard(id), data),
  deleteCustomGraphBoard: (id: ID) => apiClient.delete(CUSTOM_GRAPHS.getGraphBoard(id)),
  createGraphItem: (page?: { page: number | null; }) => apiClient.post(CUSTOM_GRAPHS.createGraphItem(), { page }),
  updateGraphItemData: (id: ID, data: UpdateGraphItemData) => apiClient.patch(CUSTOM_GRAPHS.updateGraphItem(id), data),
  deleteGraphItemData: (id: ID) => apiClient.delete(CUSTOM_GRAPHS.updateGraphItem(id)),
  getGraphEndpointList: () => apiClient.get(CUSTOM_GRAPHS.getEndpointList()),

  getCustomRequestData: (endpoint: string, params: StatsParams) => apiClient.get(CUSTOM_GRAPHS.getCustomData(endpoint), { params }),
};

export const apiErrorHandler =
  (callback = (error: AxiosError) => {}) =>
  (error: AxiosError) => {
    console.error(error);
    callback(error);
  };

export default api;
