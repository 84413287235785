import api, { apiErrorHandler } from "api";
import { StatsParams } from "api/types";
import { AxiosError, AxiosResponse } from "axios";

const requestHandler =
  (request: (params: StatsParams) => Promise<AxiosResponse>) =>
  (
    params: StatsParams,
    onLoading: (status: boolean) => void,
    onSuccess: (res: any) => void,
    onError?: (err: AxiosError) => void,
  ) => {
    onLoading(true);
    return request(params)
      .then((res) => {
        if (res.data) onSuccess(res.data);
        onLoading(false);
      })
      .catch(
        apiErrorHandler((error) => {
          onLoading(false);
          if (onError) onError(error);
        }),
      );
  };

export const handleGetReturnedDelivered = async (
  params: StatsParams,
  onLoading: (status: boolean) => void,
  onSuccess: (res: any) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getStatisticDeliveredReturned)(
    params,
    onLoading,
    onSuccess,
    onError,
  );
};

export const handleGetCsvReturnedDelivered = async (
  params: StatsParams,
  onLoading: (status: boolean) => void,
  onSuccess: (res: any) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getCsvStatisticDeliveredReturned)(
    params,
    onLoading,
    onSuccess,
    onError,
  );
};

export const handleGetRoutes = async (
  params: StatsParams,
  onLoading: (status: boolean) => void,
  onSuccess: (res: any) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getStatisticRoutes)(params, onLoading, onSuccess, onError);
};
export const handleGetActiveDays = async (
  params: StatsParams,
  onLoading: (status: boolean) => void,
  onSuccess: (res: any) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getStatisticActiveDays)(params, onLoading, onSuccess, onError);
};

export const handleGetRoutesStats = async (
  params: StatsParams,
  onLoading: (status: boolean) => void,
  onSuccess: (res: any) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getRoutesStats)(params, onLoading, onSuccess, onError);
};

export const handleGetDeliveryStats = async (
  params: StatsParams,
  onLoading: (status: boolean) => void,
  onSuccess: (res: any) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getDeliveryStats)(params, onLoading, onSuccess, onError);
};

export const handleGetDAStatusesScores = async (
  params: StatsParams,
  onLoading: (status: boolean) => void,
  onSuccess: (res: any) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getDAStatuses)(params, onLoading, onSuccess, onError);
};

export const handleGetFleetStatusesScores = async (
  params: StatsParams,
  onLoading: (status: boolean) => void,
  onSuccess: (res: any) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getFleetStatuses)(params, onLoading, onSuccess, onError);
};

export const handleGetRoutesTrends = async (
  params: StatsParams,
  onLoading: (status: boolean) => void,
  onSuccess: (res: any) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getRoutesTrends)(params, onLoading, onSuccess, onError);
};

export const handleGetDeliveryTrends = async (
  params: StatsParams,
  onLoading: (status: boolean) => void,
  onSuccess: (res: any) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getDeliveryTrends)(params, onLoading, onSuccess, onError);
};
