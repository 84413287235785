import { FC, useEffect, useState } from "react";
import Alert, { Color } from "@material-ui/lab/Alert";
import { Box, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "constants/constants";
import { formatNumber, formatNumberType } from "utils/formatNUmber";
import { DashboardStatsBlockData } from "types/DashboardRecord";
import { useNav } from "hooks/useNav";

export const DashboardStatsBlock: FC<DashboardStatsBlockData> = ({
  Icon,
  title,
  type,
  totalScore,
  prevScore,
  totalPlans,
  totalBudgets,
  completion,
  percentageFromActual,
  percentageFromPlan,
}) => {
  const classes = useStyles();
  const [statsStatus, setStatsStatus] = useState({ type: "success", isSuccess: true });

  const { 
    viewDashboardTrends
  } = useNav();

  useEffect(() => {
    switch (type) {
      case "routes":
        setStatsStatus({
          type: completion > 100 ? "success" : "error",
          isSuccess: completion > 100,
        });
        break;
      case "deliveries":
        setStatsStatus({
          type: completion > 99.5 ? "success" : "error",
          isSuccess: completion > 99.5,
        });
        break;
      case "returns":
        setStatsStatus({
          type: percentageFromActual <= 0.5 ? "success" : "error",
          isSuccess: percentageFromActual <= 0.5,
        });
        break;
      case "other":
        setStatsStatus({
          type: prevScore - totalScore >= 0 ? "success" : "error",
          isSuccess: prevScore - totalScore >= 0,
        });
        break;
    }
  }, [prevScore, totalScore, percentageFromActual, completion]);

  return (
    <Paper data-testid="testDashboardInfoBlock" className={classes.root}>
      <Box className={classes.box}>
        <Box className={classes.titleBox}>
          <Typography style={{ fontWeight: 600 }} variant="h6">
            {title}
          </Typography>
          <Icon onClick={() => viewDashboardTrends(type)} className={classes.boxIcon} />
        </Box>
        <Box className={classes.mainScoreBox}>
          <Typography style={{ fontSize: 48, fontWeight: 600, textAlign: "center" }} variant="h2">
            {formatNumber(totalScore)}
          </Typography>
        </Box>
        <Box className={classes.planSection}>
          {type === "routes" || type === "deliveries" ? (
            <>
              <Box className={classes.planBox}>
                <Typography variant="h6">{totalPlans ? totalPlans : "No plan"}</Typography>
                <Typography style={{ fontWeight: 600 }} variant="h5">
                  Plan
                </Typography>
              </Box>
              <Box className={classes.planBox}>
                <Alert
                  icon={false}
                  severity={statsStatus.type as Color}
                  className={statsStatus.isSuccess ? classes.greenAlert : classes.redAlert}
                  style={{
                    padding: "0 5px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    style={statsStatus.isSuccess ? { color: Colors.GREEN } : { color: Colors.RED }}
                    variant="h6"
                  >
                    {formatNumberType(completion)}%
                  </Typography>
                </Alert>
                <Typography style={{ fontWeight: 600 }} variant="h5">
                  Vs Plan
                </Typography>
              </Box>
            </>
          ) : type === "returns" ? (
            <>
              <Box className={classes.planBox}>
                <Typography variant="h6">{formatNumberType(percentageFromPlan)}%</Typography>
                <Typography style={{ fontWeight: 600 }} variant="h5">
                  Plan
                </Typography>
              </Box>
              <Box className={classes.planBox}>
                <Alert
                  icon={false}
                  severity={statsStatus.type as Color}
                  className={statsStatus.isSuccess ? classes.greenAlert : classes.redAlert}
                  style={{
                    padding: "0 5px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    style={statsStatus.isSuccess ? { color: Colors.GREEN } : { color: Colors.RED }}
                    variant="h6"
                  >
                    {formatNumberType(percentageFromActual)}%
                  </Typography>
                </Alert>
                <Typography style={{ fontWeight: 600 }} variant="h5">
                  Actual
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Box className={classes.planBox}></Box>
              <Box className={classes.planBox}>
                <Alert
                  icon={false}
                  severity={statsStatus.type as Color}
                  className={statsStatus.isSuccess ? classes.greenAlert : classes.redAlert}
                  style={{
                    padding: "0 5px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    style={statsStatus.isSuccess ? { color: Colors.GREEN } : { color: Colors.RED }}
                    variant="h6"
                  >
                    {prevScore - totalScore > 0
                      ? `-${formatNumber(prevScore - totalScore || 0)}`
                      : `+${formatNumber(Math.abs(prevScore - totalScore) || 0)}`}
                  </Typography>
                </Alert>
                <Typography style={{ fontWeight: 600 }} variant="h5">
                  From Previous
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    gap: "10px",
    padding: theme.spacing(2),
    boxSizing: "border-box",
    boxShadow: "none",
    borderRadius: 15,
    borderBottom: "#e8e9ee 2px solid",
  },
  box: {
    display: "flex",
    flexDirection: "column",
  },
  titleBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  boxIcon: {
    width: 30,
    height: 30,
    cursor: "pointer",
  },
  mainScoreBox: {
    margin: "20px 0",
    fontSize: 48,
  },
  planSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  planBox: {
    textAlign: "center",
  },
  greenAlert: {
    background: "#cefad0",
  },
  redAlert: {
    background: "#ffe3e6",
  },
}));
