import { makeStyles } from "@material-ui/core/styles";
import { FC, useEffect } from "react";
import { Box, FormGroup, Button } from "@material-ui/core";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { DashboardUpdateDateSettings, DashboardUpdateSettings } from "actions/dashboardActions";
import { useDispatch } from "react-redux";
import { useDateWeekSelector } from "hooks/useDateWeekSelector";
import { MonthSelect } from "components/elements/MonthSelect";
import { WeekSelect } from "components/elements/WeekSelect";
import { DatePickWithArrow } from "components/elements/DatePickWithArrow";
import { YearSelect } from "components/elements/YearSelect";
import { DashboardStats } from "./DashboardStats";
import { DashboardStatuses } from "./DashboardStatuses";
import { DashboardDrawerButton } from "components/buttons/DashboardDrawerButton";

interface PropsType {}

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  topSection: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  topSctionBox: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    flexWrap: "wrap",
  },
  leftButton: {
    boxSizing: "border-box",
    borderRadius: "25px 0 0 25px",
  },
  midButton: {
    boxSizing: "border-box",
    borderRadius: "0",
  },
  rightButton: {
    boxSizing: "border-box",
    borderRadius: "0 25px 25px 0",
  },
  "@media (max-width: 700px)": {
    topSection: {
      flexDirection: "column",
      gap: 10,
      paddingBottom: theme.spacing(1),
    },
    topSctionBox: {
      justifyContent: "center",
    },
  },
}));

const Dashboard: FC<PropsType> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userData } = useTypeSelectors((state) => state.auth);
  const { dashboardScoreSettings } = useTypeSelectors((state) => state.dashboard);

  const handleChangeWeek = (week: string) => dispatch(DashboardUpdateDateSettings({ week }));

  const handleChangeMonth = (month: string) => dispatch(DashboardUpdateDateSettings({ month }));

  const handleChangeYear = (year: string) => dispatch(DashboardUpdateDateSettings({ year }));

  const handleChangeDay = (date: string) => dispatch(DashboardUpdateDateSettings({ day: date }));

  const handleChangeExternalSelectType = (select: string) =>
    dispatch(
      DashboardUpdateSettings({
        currentDateTypeLabelDateType: select,
      }),
    );

  const {
    date,
    week,
    month,
    year,
    selectType,
    dateCondition,
    dateConditionMonth,
    onWeekReduce,
    onWeekIncrease,
    onMonthReduce,
    onMonthIncrease,
    onTypeExternalChange,
    onDateChange,
    onDateChangeByDay,
    dateConditionYear,
    onYearIncrease,
    onYearReduce,
  } = useDateWeekSelector({
    defaultWeek: dashboardScoreSettings?.labelDate?.week,
    defaultMonth: dashboardScoreSettings?.labelDate?.month,
    defaultYear: dashboardScoreSettings?.labelDate?.year,
    defaultSelectType: dashboardScoreSettings?.currentDateTypeLabelDateType,
    onSelectTypeSet: handleChangeExternalSelectType,
    onWeekSet: handleChangeWeek,
    onMonthSet: handleChangeMonth,
    onYearSet: handleChangeYear,
    onDateSet: handleChangeDay,
  });

  useEffect(() => {
    if (userData.id) {
      dispatch(
        DashboardUpdateSettings({
          currentStations: userData?.station_spots.map((el: { station: { title: string; id: number } }) => el.station.id)}),
      );
    }
  }, [userData, dispatch]);

  return (
    <Box data-testid="testDashboard" className={classes.root}>
      <Box className={classes.topSection}>
        <Box className={classes.topSctionBox}>
          {selectType === "annual" && (
            <YearSelect
              year={year}
              onNext={onYearIncrease}
              onPrev={onYearReduce}
              disabled={dateConditionYear()}
            />
          )}
          {selectType === "day" && (
            <DatePickWithArrow
              value={date}
              onChange={onDateChange}
              changeDay={onDateChangeByDay}
              displayWeek
            />
          )}
          {selectType === "month" && (
            <MonthSelect
              month={month}
              year={year}
              onNext={onMonthIncrease}
              onPrev={onMonthReduce}
              disabled={dateConditionMonth()}
            />
          )}
          {selectType === "week" && (
            <WeekSelect
              week={week}
              year={year}
              onNext={onWeekIncrease}
              onPrev={onWeekReduce}
              disabled={dateCondition()}
            />
          )}
          <FormGroup>
            <Box>
              <Button
                variant={selectType === "annual" ? "contained" : "outlined"}
                name="annual"
                className={classes.leftButton}
                onClick={() => {
                  onTypeExternalChange("annual");
                }}
              >
                Annual
              </Button>
              <Button
                variant={selectType === "month" ? "contained" : "outlined"}
                name="month"
                className={classes.midButton}
                onClick={() => {
                  onTypeExternalChange("month");
                }}
              >
                Monthly
              </Button>
              <Button
                variant={selectType === "week" ? "contained" : "outlined"}
                name="week"
                className={classes.midButton}
                onClick={() => {
                  onTypeExternalChange("week");
                }}
              >
                Weekly
              </Button>
              <Button
                variant={selectType === "day" ? "contained" : "outlined"}
                name="day"
                className={classes.rightButton}
                onClick={() => {
                  onTypeExternalChange("day");
                }}
              >
                Daily
              </Button>
            </Box>
          </FormGroup>

          <DashboardDrawerButton />
        </Box>
      </Box>
      <DashboardStats />
      <DashboardStatuses />
    </Box>
  );
};

export default Dashboard;
