import { Box, Grid } from "@material-ui/core";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { FC, useEffect, useState } from "react";
import {
  handleGetRoutesStats,
  handleGetDeliveryStats,
} from "./DashboardCharts/requests";
import { useDashboardStats } from "hooks/useDashboardStats";
import Loader from "router/components/Loader";
import { DashboardStatsBlock } from "components/elements/DashboardStatsBlock";
import { DashboardStatsBlockData } from "types/DashboardRecord";

interface PropsType {}

export const DashboardStats: FC<PropsType> = () => {
  const [loading, setLoading] = useState(false);
  const [routesPlanData, setRoutesPlanData] = useState<any>(null);
  const [deliveriesPlanData, setDeliveriesPlanData] = useState<any>(null);

  const { dashboardScoreSettings } = useTypeSelectors((state) => state.dashboard);

  const { mockedInfoData } = useDashboardStats({
    routesPlanData,
    deliveriesPlanData,
  });

  useEffect(() => {
    const params = {
      stations: dashboardScoreSettings?.currentStations?.join("_"),
      ...(dashboardScoreSettings?.currentDateTypeLabelDateType === "month" && {
        month: +Number(dashboardScoreSettings.labelDate?.month),
        year: +Number(dashboardScoreSettings.labelDate?.year),
      }),
      ...(dashboardScoreSettings?.currentDateTypeLabelDateType === "week" && {
        week: +Number(dashboardScoreSettings.labelDate?.week),
        year: +Number(dashboardScoreSettings.labelDate?.year),
      }),
      ...(dashboardScoreSettings?.currentDateTypeLabelDateType === "day" && {
        day: dashboardScoreSettings.labelDate?.day,
      }),
      ...(dashboardScoreSettings?.currentDateTypeLabelDateType === "annual" && {
        year: +Number(dashboardScoreSettings.labelDate?.year),
      }),
    };
    handleGetRoutesStats(params, setLoading, setRoutesPlanData);
    handleGetDeliveryStats(params, setLoading, setDeliveriesPlanData);
  }, [
    dashboardScoreSettings?.currentDateTypeLabelDateType,
    dashboardScoreSettings?.labelDate?.month,
    dashboardScoreSettings?.labelDate?.year,
    dashboardScoreSettings?.labelDate?.week,
    dashboardScoreSettings?.labelDate?.day,
    dashboardScoreSettings?.currentStations,
  ]);

  return (
    <Box data-testid="testDashboard">
      <Loader isLoading={loading} />
      <Grid container spacing={3}>
        <Grid item sm={12} md={8} lg={12}>
          <Grid container spacing={2} style={{ height: "100%" }}>
            {mockedInfoData.map((el: DashboardStatsBlockData) => (
              <Grid key={el.title} item sm={12} md={12} lg={6}>
                <DashboardStatsBlock
                  key={el.title}
                  title={el.title}
                  type={el.type}
                  Icon={el.Icon}
                  totalScore={el?.totalScore}
                  prevScore={el?.prevScore}
                  totalPlans={el?.totalPlans}
                  totalBudgets={el.totalBudgets}
                  completion={el?.completion}
                  percentageFromActual={el?.percentageFromActual}
                  percentageFromPlan={el?.percentageFromPlan}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
