import { Box, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FC } from "react";

interface DashboardTrendsTemlateType {
    GraphConponent: (() => JSX.Element) | undefined;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    gap: "10px",
    padding: theme.spacing(2),
    boxSizing: "border-box",
    boxShadow: "none",
    borderRadius: 15,
    borderBottom: "#e8e9ee 2px solid",
  },
}));

export const DashboardTrendsTemlate: FC<DashboardTrendsTemlateType> = ({ GraphConponent }) => {
  const classes = useStyles();
  
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item sm={12} md={8} lg={12}>
          <Grid container spacing={2} style={{ height: "100%" }}>
            <Grid item sm={12} md={12} lg={6}>
              <Paper className={classes.root}>
                {GraphConponent ? <GraphConponent/> : ""}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
